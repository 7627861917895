import React from "react";
import ControlPanel from "./components/ControlPanel";

import { PageChangeProvider } from "contexts/PageChangeContext";
import { ScrollProvider } from "contexts/ScrollContext";

function App() {
	return (
		<ScrollProvider>
			<PageChangeProvider>
				<div className="App">
					<ControlPanel />
				</div>
			</PageChangeProvider>
		</ScrollProvider>
	);
}

export default App;
