/**
 * @fileoverview hero controller component
 */
import React from "react";

import Hero from "./Hero";

import { Routes, Route } from "react-router-dom";

const HeroController = () => {
	return (
		<Routes>
			<Route path="/*" element={<Hero isOpen={false}/>} />
			<Route path="/signup" element={<Hero isOpen={true}/>} />
		</Routes>
	);
};

export default HeroController;
