/**
 * @file useAddWaitlistUser.js
 */

import { useMutation } from "@apollo/client";
import { ADD_WAITLIST_USER } from "cache/WaitlistUser/mutations";  // Update the path as per your project structure

/**
 * @hook useAddWaitlistUser
 *
 * @description
 * Custom hook for adding a user to the waitlist.
 * Use this hook in components where you need to add a user to the waitlist.
 *
 * @returns
 * - addWaitlistUserOp: The operation to add a user to the waitlist
 * - loading: Boolean indicating the loading state
 * - error: Error object if there's an error
 */
const useAddWaitlistUser = () => {

	// Define the Apollo useMutation hook
	const [addWaitlistUserMutation, { loading, error }] = useMutation(ADD_WAITLIST_USER);

	/**
	 * Executes the addWaitlistUser mutation.
	 *
	 * @param {String} email - The email of the user to add to the waitlist.
	 * @param {String} firstName - The first name of the user to add to the waitlist.
	 * @param {String} lastName - The last name of the user to add to the waitlist.
	 *
	 * @returns {Object} - An object containing the user data that was added to the waitlist or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const addWaitlistUser = async (email, firstName, lastName, code = "") => {
		try {
			const { data } = await addWaitlistUserMutation({
				variables: { 
					input: {
						email,
						firstName,
						lastName,
						code,
					}
				}
			});
			if (error) {
				throw new Error(error);
			}
			// Return added user's data
			return { data: data.addWaitlistUser };
		} catch (error) {
			return { error };
		}
	};

	return { addWaitlistUser, loading, error };
};

export default useAddWaitlistUser;
