/**
 * @file mutations.js is a file for all the queries used in the WaitlistUser component.
 */
import { gql } from "@apollo/client";

/**
 * @mutation ADD_WAITLIST_USER
 * 
 * @desc Adds a user to the waitlist
 *
 * @param {String} email - The email of the user
 * @param {String} firstName - The first name of the user
 * @param {String} lastName - The last name of the user
 * 
 * @returns {Object} - The user that was added to the waitlist 
 */
export const ADD_WAITLIST_USER = gql`
	mutation addWaitlistUser($input: AddWaitlistUser!) {
		addWaitlistUser(input: $input) {
			_id
			email
			firstName
			lastName
			code
		}
	}
`;
