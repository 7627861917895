/**
 * @file PageChangeContext.js is a context that provides the page and handlePageChange to the children components
 */
import React, { createContext, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ScrollContext } from "./ScrollContext";

/**
 * @component PageChangeContext
 * @category Contexts
 * @subcategory PageChangeContext
 * @returns {Object} - the PageChangeContext object
 * @description - the PageChangeContext object that provides the page and handlePageChange to the children components
 * @property page: the current page
 * @function handlePageChange: the handler to change the page
 *  - param {String} page - the page to navigate to
 *  - param {Object} searchParams - the search params to set, optional
 *  - returns {void}
 * @function redirectToLogin: Redirects user to login screen and sets the redirect search param
 *  @example 
 * 
 *  import { PageChangeContext } from './PageChangeContext';
 *   
 *  const { page, handlePageChange, redirectToLogin } = useContext(PageChangeContext);
 *  
 *  <button 
 *      onClick={() => handlePageChange("home", {"redirect": "profile"})}
 *  >
 *      Home
 *  </button>
 * 
 */
export const PageChangeContext = createContext();

/**
 * @function PageChangeProvider
 * 
 * @param {Object} children - the children components to render
 * @returns {Object} - the rendered PageChangeContext.Provider component
 * @description - the PageChangeContext.Provider component that provides the page and handlePageChange to the children components 
 */
export const PageChangeProvider = ({ children }) => {

	const { scrollToTop } = useContext(ScrollContext); // Scroll to top when the page changes

	const navigate = useNavigate();
	// eslint-disable-next-line no-unused-vars
	const setSearchParams = useSearchParams()[1];

	// Get the initial page from the url
	let initialPage = window.location.pathname.slice(1);
	const [page, setPage] = useState(initialPage);

	/**
     * Handler to change the page and navigate the user to the new page
     * 
     * @param {String} page - the page to navigate to
     * @param {Object} searchParams - the search params to set, optional
     * 
     * @returns {void}
     * @description the user is navigated to the new page, page is scrolled to top
     * @example handlePageChange("home", { redirect: "search" });
     */
	const handlePageChange = (page, searchParams) => {
		//If page starts with a /, remove it
		if(page.charAt(0) === "/"){
			page = page.slice(1);
		}
		setPage(page);
		if(searchParams) {
			navigate(`/${page}`);
			setSearchParams(searchParams);
		}
		else{
			navigate(`/${page}`);
		}
		setTimeout(() => {
			scrollToTop();
		}, 100);
	};

	return (
		<PageChangeContext.Provider value={{ page, handlePageChange }}>
			{children}
		</PageChangeContext.Provider>
	);
};


