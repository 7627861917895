// components/Footer/index.js
import React, { useContext } from "react";

import { PageChangeContext } from "contexts/PageChangeContext";

import { Flex, Text, Stack, Button } from "@chakra-ui/react";

const Footer = () => {

	const { handlePageChange } = useContext(PageChangeContext);

	return (
		<Flex
			bg="gray.200"
			color="gray.800"
			py={4}
			justifyContent="space-evenly"
			alignItems="center"
			w="100%"
			h="100%"
			direction={{
				base: "column",
				md: "row",
			}}
			gap={{
				base: 4,
				md: 0,
			}}
		>
			<Text
				w={{
					base: "100%",
					md: "50%",
				}}
				textAlign="center"
			>
				&copy; {new Date().getFullYear()} MyProjectCar.com. All rights reserved.
			</Text>
			<Stack
				direction="row"
				spacing={4}
				w={{
					base: "100%",
					md: "50%",
				}}
				justifyContent="center"
			>
				<Button
					variant="link"
					onClick={() => handlePageChange("/policies/privacy")}
					_hover={{ transform: "translateY(-2px)", color: "gradient_mpc.500" }}
				>
					Privacy Policy
				</Button>
				<Button
					variant="link"
					onClick={() => handlePageChange("/policies/terms")}
					_hover={{ transform: "translateY(-2px)", color: "gradient_mpc.500" }}
				>
					Terms of Service
				</Button>
			</Stack>
		</Flex>
	);
};

export default Footer;

