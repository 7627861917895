import React, { useState } from "react";
import {
	Box,
	Flex,
	Text,
	FormControl,
	FormLabel,
	Input,
	Button,
	useToast,
} from "@chakra-ui/react";

import ErrorMsg from "Blueprints/ErrorMsg";

import useAddWaitlistUser from "hooks/WaitlistUser/mutations/useAddWaitlistUser";

import { useSearchParams } from "react-router-dom";

import { sanitizeInput, isValidEmail } from "utils/utils";

const WaitlistForm = ({ onClose }) => {
	
	const toast = useToast();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	let searchParams = useSearchParams()[0];
	let code = searchParams.get("code");
	if (code) {
		code = code.trim();
	}
	else{
		code = "";
	}

	const [input, setInput] = useState({
		firstName: "",
		lastName: "",
		email: "",
		code: code,
	});

	const [fieldErrors, setFieldErrors] = useState({
		firstName: null,
		lastName: null,
		email: null,
	});

	const handleUpdateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: sanitizeInput(e.target.value),
		});
	};

	const validateInput = () => {
		let valid = true;
		const newFieldErrors = {
		  firstName: null,
		  lastName: null,
		  email: null,
		};
	
		if (!input.firstName) {
		  	newFieldErrors.firstName = "First name is required.";
		  	valid = false;
		}
		if (!input.lastName) {
		  	newFieldErrors.lastName = "Last name is required.";
		  	valid = false;
		}
		if (!input.email) {
		  	newFieldErrors.email = "Email is required.";
		  	valid = false;
		}
		if(!isValidEmail(input.email)){
			newFieldErrors.email = "Please enter a valid email.";
		  	valid = false;
		}
		setFieldErrors(newFieldErrors);
		return valid;
	};
  
	const { addWaitlistUser } = useAddWaitlistUser();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!validateInput()) return;
		setLoading(true);
		const { error } = await addWaitlistUser(input.email, input.firstName, input.lastName, input.code);
		if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
			return;
		}
		setLoading(false);
		setInput({
			firstName: "",
			lastName: "",
			email: "",
			code: code,
		});
		toast({
			title: "Success",
			description: "You've redeemed your offer for a free month of our V12 membership. We will notify you when we launch.",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
		onClose();
	};

	return (
		<Flex 
			maxW="8xl"
			mx="auto"
			my="auto"
			width="full"
			justifyContent="center"
			alignItems="center"
			onClick={(e) => e.stopPropagation()}
		>
			<Box 
				p={8}
				borderWidth={1} 
				borderRadius="md" 
				bg="white"
				boxShadow="dark-lg"
				width="full"
				maxWidth="500px"
				height="full"
				maxHeight="100%"
			>
				<Text 
					fontSize="2xl" 
					mb={4}
					fontWeight="bold"
					textAlign="center"
				>
					Sign up to Redeem
				</Text>
				<Text 
					fontSize="md" 
					mb={6}
					fontWeight="normal"
					textAlign="center"
				>
					We'll notify you when MyProjectCar.com launches and give you a free month of V12 premium membership.
				</Text>
				<form onSubmit={handleSubmit}>
					<Flex flexDirection="column">
						{error && <ErrorMsg error={error} />}
						<FormControl 
							id="email" 
							isRequired 
							isInvalid={fieldErrors.email}
							onClick={(e) => e.stopPropagation()}
						>
							<FormLabel>Email address</FormLabel>
							<Input
								type="email"
								placeholder="Enter your email address"
								name="email"
								value={input.email}
								onChange={handleUpdateInput}
								borderColor="gray.400"
								borderWidth="1"
								borderRadius="lg"
								boxShadow="md"
							/>
						</FormControl>
						<FormControl id="firstName" isRequired isInvalid={fieldErrors.firstName}>
							<FormLabel mt={4}>First Name</FormLabel>
							<Input
								type="text"
								placeholder="Enter your first name"
								name="firstName"
								value={input.firstName}
								onChange={handleUpdateInput}
								borderColor="gray.400"
								borderWidth="1"
								borderRadius="lg"
								boxShadow="md"
							/>
						</FormControl>
						<FormControl id="lastName" isRequired isInvalid={fieldErrors.lastName}>
							<FormLabel mt={4}>Last Name</FormLabel>
							<Input
								type="text"
								placeholder="Enter your last name"
								name="lastName"
								value={input.lastName}
								onChange={handleUpdateInput}
								borderColor="gray.400"
								borderWidth="1"
								borderRadius="lg"
								boxShadow="md"
							/>						
						</FormControl>
						<FormControl id="code">
							<FormLabel mt={4}>Code (optional)</FormLabel>
							<Input
								type="text"
								placeholder="Enter your code"
								name="code"
								value={input.code}
								onChange={handleUpdateInput}
								borderColor="gray.400"
								borderWidth="1"
								borderRadius="lg"
								boxShadow="md"
							/>
						</FormControl>
						<Button 
							mt={8} 
							variant="outline" 
							type="submit"
							isLoading={loading}
							loadingText="Submitting"
						>
              				Redeem
						</Button>
						<Text
							mt={4}
							fontSize="sm"
							textAlign="center"
							color="gray.500"
						>
							By clicking Redeem, you agree to our{" "}
							{/* Make sure they agree to our privacy policy and that they agree to receive communications */}
							policies including our Terms of Service and Privacy Policy
							{" "}and consent to receive communications from MyProjectCar.com.
						</Text>
					</Flex>
				</form>
			</Box>
		</Flex>
	);
};
  
export default WaitlistForm;
  