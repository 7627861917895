/**
 * @file utils.js is a file that contains all the utility functions used in the application.
 */
import * as EmailValidator from "email-validator";
import DOMPurify from "dompurify";

export const sanitizeInput = (value) => {
	// Use a DOMPurify or a similar library to sanitize user input against any malicious strings.
	// For this example, I'll use a simple replace.
	return DOMPurify.sanitize(value);
};

/**
 * @function isValidEmail
 * 
 * @description
 *  - This function checks if the email address is valid or not, according to the following rules:
 *     - The email address must contain an @ symbol.
 *     - The email address must contain a . symbol.
 *     - The email address must contain at least 2 characters after the . symbol.
 *     - The email address must not contain any special characters.
 *     - The email address must not contain any spaces.
 * 
 * @param {string} email The email address to be validated.
 * @returns {boolean} True if the email is valid, false otherwise.
 */
export function isValidEmail(email) {
	// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	// let isValid = emailRegex.test(email);
	let isValid = EmailValidator.validate(email);
	return isValid;
}
