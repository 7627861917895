/**
 * @file ModalWrapper.js is a wrapper for the Modal component from Chakra UI.
 */
import React from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton, 
} from "@chakra-ui/react";

/**
 * @function ModalWrapper
 * 
 * @description
 * A custom wrapper component around Chakra UI's Modal that provides more customization and abstraction.
 * 
 * @preconditions
 * - `isOpen` should be a boolean value.
 * - `onClose` should be a valid function that handles the closing of the modal.
 * - `children` can be any valid React node(s).
 * - `props` may contain additional properties like `size` and `heading`.
 * 
 * @param {boolean} isOpen - Indicates whether the modal is open or closed.
 * @param {function} onClose - Function to call when the modal needs to be closed.
 * @param {React.ReactNode} children - The content that will go in the modal's body.
 * @param {object} props - Additional properties for customization.
 * @param {string} [props.size='2xl'] - The size of the modal. Defaults to "2xl".
 * @param {string} [props.heading] - Optional heading text for the modal.
 * 
 * @postconditions
 * - The Modal is rendered based on the provided props.
 * - If `props.heading` is provided, a header is rendered with the heading text.
 * - If `isOpen` is true, the Modal will be shown.
 * 
 * @returns {JSX.Element} The fully constructed modal component with the customizations and children.
 */
const ModalWrapper = ({ isOpen, onClose, children, ...props }) => {

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			size={props.size ? props.size : "2xl"}
		>
			<ModalOverlay />
			<ModalContent
				bg="transparent"
				blur="auto"
			>
				{
					props.heading &&
					<ModalHeader>
						{props.heading}
					</ModalHeader>
				}
				<ModalCloseButton 
					bg="white"
					mx={{
						base: 5,
					}}
					my={{
						base: 2,
					}}
				/>
				<ModalBody>
					{children}
				</ModalBody>
			</ModalContent>
		</Modal>
	);

};

export default ModalWrapper;
