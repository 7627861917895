import React, { useContext } from "react";

import { PageChangeContext } from "contexts/PageChangeContext";

import { 
	Box, 
	Text, 
	Heading, 
	UnorderedList, 
	ListItem,
	Button,
	Flex,
} from "@chakra-ui/react";

const parseMarkdownText = (text) => {
	//Handle text between ** ** for bold using Strong
	if (text.includes("**")) {
		const splitText = text.split("**");
		const boldText = splitText.map((text, index) => {
			if (index % 2 === 0) {
				return text;
			} else {
				return <strong key={index}>{text}</strong>;
			}
		});
		return boldText;
	}
	return text;
};

const parseMarkdown = (content) => {
	const lines = content.split("\n");
	return lines.map((line, index) => {
		if (line.startsWith("# ")) {
			return <Heading variant={"orange_heading"} size="xl" mb={2} key={index}>{line.substring(2)}</Heading>;
		} else if (line.startsWith("## ")) {
			return <Heading variant={"orange_heading_small"} size="lg" mb={2} key={index}>{line.substring(3)}</Heading>;
		} else if (line.startsWith("### ")) {
			return <Heading size="md" key={index} mb={2}>{line.substring(4)}</Heading>;
		} else if (line.startsWith("#### ")) {
			return <Heading size="sm" key={index} mb={2}>{line.substring(5)}</Heading>;
		} else if (line.startsWith("##### ")) {
			return <Heading size="xs" key={index} mb={2}>{line.substring(6)}</Heading>;
		} else if (line.startsWith("- ")) {
			return <UnorderedList key={index} mb={2}><ListItem mb={2}>{parseMarkdownText(line.substring(2))}</ListItem></UnorderedList>;
		} else {
			return <Text key={index} mb={2}>{parseMarkdownText(line)}</Text>;
		}
	});
};

const PolicyRenderer = ({ policyContent }) => {

	const { handlePageChange } = useContext(PageChangeContext);

	return (
		<Flex
			w="100%"
			h="100%"
			flexDir="column"
			align="center"
			justify="center"
			p={"1rem"}
		>
			<Box padding="4" borderWidth="1px" borderRadius="lg" spacing={3}>
				{parseMarkdown(policyContent)}
			</Box>
			<Button
				variant="primary"
				size="lg"
				mt={4}
				onClick={() => handlePageChange("/")}
			>
				Back to Home
			</Button>
		</Flex>
		
	);
};

export default PolicyRenderer;
