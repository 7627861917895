import React from "react";

import {
	Alert,
	AlertIcon,
	AlertDescription,
} from "@chakra-ui/react";

/**
 * @component ErrorMsg
 * 
 * @description
 * - This component is used to display an error message
 */
const ErrorMsg = ({  error, children}) => {
	return (
		<Alert status="error">
			<AlertIcon />
			<AlertDescription>{children} {error}</AlertDescription>
		</Alert>
	);
};

export default ErrorMsg;