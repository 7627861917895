/**
 * @file PolicyController.js
 */
import React from "react";

import { Routes, Route } from "react-router-dom";

import PolicyRenderer from "./PolicyRenderer";

import { tos, privacy } from "./policyData";


/**
 * @component PolicyController
 * 
 * @description
 * - Controller for the Policies
 * - Handles the rendering of the various policy pages
 */
const PolicyController = () => {
	return (
		<Routes>
			<Route path="/privacy" element={<PolicyRenderer policyContent={privacy}/>} />
			<Route path="/terms" element={<PolicyRenderer policyContent={tos}/>} />
		</Routes>
	);
};

export default PolicyController;