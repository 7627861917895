/**
 * @file useSendContactFormEmail.js
 * 
 * Custom hook that handles sending contact form emails.
 */

import { useMutation } from "@apollo/client";
import { SEND_CONTACT_EMAIL } from "cache/Contact/mutations"; // Update the path as per your project structure

/**
 * @hook useSendContactFormEmail
 *
 * @description
 * Custom hook that manages the sending of a contact form email.
 *
 * @returns
 * - sendEmail: A custom function to execute the sendContactFormEmail mutation.
 * - loading: Boolean indicating the loading state.
 * - error: Error object if there's an error.
 */
const useSendContactEmail = () => {

	// Declare Apollo Client's mutation function
	const [sendContactEmailMutation, { loading, error }] = useMutation(SEND_CONTACT_EMAIL);

	/**
	 * Custom method to send email.
	 *
	 * @param {Object} input - The input object for sending a contact form email.
	 * @property {String} input.subject - The subject line of the email.
	 * @property {Object} input.formData - The data from the contact form. This object can have the following properties:
	 *  - {String} name (or firstName and lastName)
	 *  - {String} email
	 *  - {String} phone
	 *  - {String} message
	 *
	 * @returns {Object} - An object containing either a boolean indicating the success of the operation or an error object.
	 * @throws {Error} - Throws an error if the mutation execution fails.
	 */
	const sendEmail = async (formData) => {
		try {
			const { data } = await sendContactEmailMutation({
				variables: {
					formData
				}
			});
			
			// Handle errors from the mutation execution
			if (error) {
				throw new Error(error);
			}
			
			// Return success status
			return { success: data.sendContactEmail };
		} catch (mutationError) {
			return { error: mutationError };
		}
	};

	return { sendEmail, loading, error };
};

export default useSendContactEmail;
