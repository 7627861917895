/**
 * @file constants.js is a file that contains all the constants used in the application.
 */

// import { membershipTiers } from "components/Pages/Store/Membership/components/membershipData";

/**
 * @constants Webify Development Colors
 * 
 * @description
 * - Hex values for the Webify Development color palette.
 * - ERROR_RED: #B03049
 * - WARNING_YELLOW: #FFCC00
 * - SUCCESS_GREEN: #30B159
 * - INFO_BLUE: #0066FF
 */
export const ERROR_RED	= "#B03049";
export const WARNING_YELLOW = "#FFCC00";
export const SUCCESS_GREEN = "#30B159";
export const INFO_BLUE = "#0066FF";

/**
 * @constants Webify Mobile Colors
 * 
 * @description
 * - Hex values for the Webify Mobile color palette.
 * = MOBILE_PRIMARY: #FF6B4C
 * - MOBILE_SECONDARY: #FE7656
 * - MOBILE_ROYAL_BLUE: #3F60E1
 * - MOBILE_BACKGROUND: #E5E5E5
 * - MOBILE_DARK: #1F2937
 * - MOBILE_TEXT: #000000
 * - MOBILE_HEADER: #3F60E1
 * - MOBILE_BUTTON: #FF6647
 * - MOBILE_BUTTON_TEXT: #FFFFFF
 * - MOBILE_BORDER: #CCCCCC
 */
export const MOBILE_PRIMARY = "#FF6B4C";  // Lighter MPC_ORANGE
export const MOBILE_SECONDARY = "#FE7656";  // Lightest MPC_ORANGE
export const MOBILE_ROYAL_BLUE = "#3F60E1";  // royal blue
export const MOBILE_BACKGROUND = "#E5E5E5";  // light gray
export const MOBILE_DARK = "#1F2937";  // dark blue
export const MOBILE_TEXT = "#242124";  // black
export const MOBILE_HEADER = "#3F60E1";  // royal blue
export const MOBILE_BUTTON = "#FF6647";  // orange
export const MOBILE_BUTTON_TEXT = "#FFFFFF";  // white
export const MOBILE_BORDER = "#CCCCCC";  // gray

/**
 * @constants MPC Colors
 * 
 * @description
 * - Hex values for the MPC color palette, specific to this application.
 * - MPC_ORANGE: #FF6647
 * - MPC_WHITE: #FFFFFF
 * - MPC_BLACK: #000000
 * - MPC_GRAY: #484954
 */
export const MPC_ORANGE = "#FF6647";
export const MPC_WHITE = "#FFFFFF";
export const MPC_BLACK = "#000000";
export const MPC_GRAY = "#484954";
export const MPC_GRAY2 = "#242124";
export const MPC_GUNMETAL = "#2A3439";
export const MPC_GHOSTMETAL = "#7A8890";
export const MPC_BORDER = "#F2FBFF";

/**
 * @constants MPC Functional Constants
 * 
 * @description
 * - Lists containing all the values for some of the MPC selection menus
 * - CONDITIONS: list of possible condition values
 * - POST_TYPES: list of possible postTypes
 */
export const CONDITIONS = [
	{
		id: 0,
		value: "new",
		label: "New"
	},
	{
		id: 1,
		value: "good",
		label: "Good"
	},
	{
		id: 2,
		value: "fair",
		label: "Fair"
	},
	{
		id: 3,
		value: "poor",
		label: "Poor"
	},
];
export const POST_TYPES = [
	{
		id: 0,
		value: "buying",
		label: "Buying"
	}, 
	{
		id: 1,
		value: "selling",
		label: "Selling"
	},
];

// Carmunity / MyGarage / Account
export const ALERT_TYPES = [
	{ //General Alerts that don't belong to any specific category
		id: 0,
		value: "general",
		label: "General"
	},
	{ // Alerts pertaining to the Carmunity
		id: 1,
		value: "carmunity",
		label: "Carmunity"
	},
	{ // Alerts pertaining to the MyGarage
		id: 2,
		value: "mygarage",
		label: "MyGarage"
	},
	{ // Alerts pertaining to the Account including Memberships, Credits, etc.
		id: 3,
		value: "account",
		label: "Account"
	},
	{ // Alerts pertaining to the Admin 
		id: 4,
		value: "admin",
		label: "Admin"
	} 
];

export const categories = {
	"Engine Components": [
		"ECU",
		"Engine valves",
		"Pistons",
		"Camshaft",
		"Crankshaft",
		"Timing belt/chain",
		"Oil filter",
		"Air filter",
		"Fuel pump",
		"Water pump",
		"Radiator",
		"Turbocharger",
		"Supercharger"
	],
	"Transmission System": [
		"Clutch",
		"Gearbox", 
		"Drive shaft",
		"Differential",
		"Axles"
	],
	"Braking System": [
		"Brake pads",
		"Brake discs",
		"Brake calipers",
		"Brake master cylinder",
		"Brake booster",
		"Brake lines",
		"Brake fluid"
	],
	"Suspension System": [
		"Shock absorbers",
		"Struts",
		"Springs",
		"Control arms",
		"Ball joints",
		"Sway bars",
		"Bushings"
	],
	"Exhaust System": [
		"Exhaust manifold",
		"Catalytic converter",
		"Muffler",
		"Exhaust pipes"        
	],
	"Electrical System": [
		"Battery",
		"Alternator",
		"Starter",
		"Ignition coil",
		"Spark plugs",
		"Headlights",
		"Tail lights",
		"Turn signals",
		"Fog lights",
		"Brake lights",
		"Interior lights",
		"Instrument cluster",
		"ECU",
		"Wiring harness",
		"Fuse box"        
	],
	"Interior": [
		"Seats",
		"Steering wheel",
		"Dashboard",
		"Console",
		"Carpets/Mats",
		"Seat belts",
		"Airbags"
	],
	"Exterior": [
		"Doors",
		"Hood",
		"Trunk lid",
		"Bumpers",
		"Fenders",
		"Mirrors",
		"Windshield",
		"Windows"        
	],
	"Heating & Cooling": [
		"AC Compressor",
		"AC Condenser",
		"Heater core",
		"Blower motor",
		"Thermostat"        
	],
	"Fuel System": [
		"Fuel pump",
		"Fuel filter",
		"Fuel injectors",
		"Fuel tank",
		"Fuel lines"        
	],
};

export const MAX_PRICE = 1000000;

/**
 * @constants Credit Packs
 * 
 * @description
 * - CREDIT_PACKS: list of possible credit conversions
 * 
 * @returns {array} CREDIT_PACKS - array of pack objects
 * @property {int} id: unique id for each pack
 * @property {int} numCredits: number of credits in the pack
 * @property {float} numDollars: number of dollars the pack costs
 * @property {int} extra: number of extra credits in the pack
 * @property {string} stripe_price_id: stripe price id for the pack
 */
export const CREDIT_PACKS = [
	{
		id: 0,
		numCredits: 5, //Baseline conversion
		numDollars: 5.00,
		extra: 0,
		stripe_price_id: "price_1NZGjmIiZdlXsnqKh2faCsY1",
	},
	{
		id: 1,
		numCredits: 12, //2 credits extra
		numDollars: 10.00,
		extra: 2,
		stripe_price_id: "price_1NZGkFIiZdlXsnqK9kJj8dgR",
	},
	{
		id: 2,
		numCredits: 35, //5 credits extra
		numDollars: 30.00,
		extra: 5,
		stripe_price_id: "price_1NZGkjIiZdlXsnqKktz8jtpE",
	},
	{
		id: 3,
		numCredits: 65, //15 credits extra
		numDollars: 50.00,
		extra: 15,
		stripe_price_id: "price_1NZGl8IiZdlXsnqKyCRSdPnO",
	},
];

/**
 * @constants Memberships 
 * 
 * @description
 * - MEMBERSHIPS: list of possible memberships
 */
export const MEMBERSHIPS = [
	{
		id: 0,
		value: "v6",
		label: "V6",
		name: "V6",
		description: "Free membership",
		features: ["Unlimited buying posts", "5 active selling posts", "5% transaction fee"],  // V6 features
		price: {
		},
		stripe_price_id: {
			month: "N/A",
			year: "N/A"
		}
	},
	{
		id: 1,
		value: "v8",
		label: "V8",
		name: "V8",
		description: "For Enthusiasts",
		features: ["One Car Garage", "10 active selling posts", "4% transaction fee"],  // V8 features
		price: {
			month: 8.00,
			year: 89, // Yearly price assuming some discount
		},
		stripe_price_id: {
			month: "price_1NZGooIiZdlXsnqKxULM79CW",
			year: "price_1NZGooYearly"
		}
	},
	{
		id: 2,
		value: "v12",
		label: "V12",
		name: "V12",
		description: "For Professionals",
		features: ["Two Car Garage", "15 active selling posts", "3% transaction fee", "Access to upcoming features"],  // V12 features
		price: {
			month: 12.00,
			year: 129, // Yearly price assuming some discount
		},
		stripe_price_id: {
			month: "price_1NZGpOIiZdlXsnqKzR21Bhi2",
			year: "price_1NZGpOYearly"
		}
	}
];




export const OFFER_STATUSES = [
	{
		id: 0,
		value: "pending",
		label: "Pending"
	},
	{
		id: 1,
		value: "accepted",
		label: "Accepted"
	},
	{
		id: 2,
		value: "declined",
		label: "Declined"
	},
];

export const ACCEPTED_COUNTRIES = [
	{
		id: 0,
		value: "US",
		label: "United States",
	},
];

/**
 * @const SHIPPING_CARRIERS
 * 
 * @description 
 * - Defines the available shipping carriers MPC provides
 */
export const SHIPPING_CARRIERS = [
	{
		id: 0,
		value: "usps",
		label: "USPS",
	},
	{
		id: 1,
		value: "ups",
		label: "UPS",
	},
	{
		id: 3,
		value: "dhl",
		label: "DHL",
	}
];

/**
 * @const POST_IMAGES_BUCKET_NAME
 */
export const POST_IMAGES_BUCKET_NAME = "mpc-post-images";