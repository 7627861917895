import React, { useState, useEffect } from "react";
import { Box, Heading, Container, Stack, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Element } from "react-scroll";
import PricingCardsContainer from "./PricingCard";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);

const CTASection = () => {
	const [scale, setScale] = useState(0.8);  // Initial scale
  
	useEffect(() => {
	  const handleScroll = () => {
			const element = document.querySelector("#pricingCardElement");
			const rect = element.getBoundingClientRect();
			if (rect.top <= window.innerHeight && rect.bottom >= 0) {
		  // Dynamically set the scale value based on scroll position
		  const newScale = Math.min(1, 0.8 + window.scrollY / 2000);
		  setScale(newScale);
			}
	  };
  
	  window.addEventListener("scroll", handleScroll);
  
	  return () => {
			window.removeEventListener("scroll", handleScroll);
	  };
	}, []);

	return (
		<MotionBox
			h={{
				base: "auto",
				md: "100vh",
			}}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1, delay: 0.5 }}
		>
			<Container
				py={{
					base: "auto",
					md: "24",
				}}
				maxW="container.xl"
			>
				<Stack
					spacing={{
						base: "4",
						md: "6",
					}}
					textAlign="center"
				>
					<MotionHeading
						as="h2"
						size={{
							base: "lg",
							md: "xl",
						}}
						mt={{
							base: "4"
						}}
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 1, delay: 1 }}
						color="#242124"
						textShadow="0px 2px 4px rgba(0, 0, 0, 0.22)"
					>
						Your free month of V12 premium membership includes...
					</MotionHeading>
					<Flex
						flexDirection={{
							base: "column",
							md: "row",
						}}
						justifyContent="center"
						alignItems="center"
						w="full"
						
					>
						<Element name="pricingCardElement" id="pricingCardElement">
							<motion.div
								initial={{ scale: 0.8 }}
								animate={{ scale: scale }}
								transition={{ duration: 0.2 }}
							>
								<PricingCardsContainer />
							</motion.div>
						</Element>
					</Flex>
				</Stack>
			</Container>
		</MotionBox>
	);
};

export default CTASection;
