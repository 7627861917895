// LandingPage.js
import React from "react";
import { motion } from "framer-motion";
import useAnimControl from "../../hooks/useAnimatedControl";

import HeroController from "./Hero/HeroController";
import CTA from "./CTA/CTA";
import About from "./About/About";
import Features from "./Features/Features";
import Form from "./Forms/ContactForm";
import Footer from "./Footer/Footer";

const LandingPage = () => {

	const heroAnim = useAnimControl();
	const ctaAnim = useAnimControl();
	const featuresAnim = useAnimControl();
	const formAnim = useAnimControl();

	return (
		<div>
			<motion.div {...heroAnim} variants={{ visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: 100 } }}>
				<HeroController />
			</motion.div>

			<motion.div {...ctaAnim} variants={{ visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: 100 } }}>
				<CTA />
			</motion.div>

			<motion.div {...ctaAnim} variants={{ visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: 100 } }}>
				<About />
			</motion.div>

			<motion.div {...featuresAnim} variants={{ visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: 100 } }}>
				<Features />
			</motion.div>

			<motion.div 
				{...formAnim} 
				variants={{
					visible: { opacity: 1, y: 0 }, 
					hidden: { opacity: 0, y: 100 }
				}} 
			>
				<Form />
			</motion.div>

			<Footer />
		</div>
	);
};

export default LandingPage;

