/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import {
	Box,
	Button,
	HStack
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import Garage from "../../../assets/Garage3.png";
import Logo from "../../../Blueprints/Logo";

import WaitlistForm from "../Forms/WaitlistForm";
import ModalWrapper from "wrappers/ModalWrapper";

import "./Hero.scss";

const MotionBox = motion(Box);
const MotionButton = motion(Button);
const MotionLogoBox = motion(Box);

const HeroSection = (props) => {

	const [isOpen, setIsOpen] = React.useState(props.isOpen);
	const onClose = () => setIsOpen(false);

	return (
		<MotionBox
			h={{
				base: "100vh",
				lg: "100vh"
			}}
			p={{
				base: 4,
				lg: "block",
			}}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1 }}
			style={{
				backgroundImage: `url(${Garage})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat"
			}}
		>
			<MotionLogoBox
				bg="white"
				borderRadius="lg"
				p={{
					base: "auto",
					lg: 4
				}}
				boxShadow="dark-lg"
				maxW="100%"
				minW={{
					base: "auto",
					lg: "32vw"
				}}
				minH={{
					base: "auto",
					lg: "12vh"
				}}
				maxH={{
					base: "auto",
					lg: "22vh"
				}}
				justifyContent="center"
				alignItems="center"
				display="flex"
				className="animated lightSpeedIn"
			>
				<Logo />
			</MotionLogoBox>
			<HStack>
				<MotionButton
					variant="primary"
					initial={{ y: 0 }}
					animate={{ y: [0, -10, 0] }}
					transition={{ duration: 0.8, loop: Infinity }}
					mt={4}
					p={{
						base: 4,
						lg: "block",
					}}
					fontSize={{
						base: "lg",
						lg: "md",
					}}
					onClick={() => setIsOpen(true)}
				>
					Redeem your Special Offer!
				</MotionButton>
				<ModalWrapper isOpen={isOpen} onClose={onClose}>
					<WaitlistForm 
						onClose={onClose}
					/>
				</ModalWrapper>
			</HStack>
		</MotionBox>
	);
};

export default HeroSection;
