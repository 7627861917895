// Bryans first front-end hook :)

import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const useAnimControl = () => {
	const controls = useAnimation();
	const [ref, inView] = useInView({
		threshold: 0.1, 
	});

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		} else {
			controls.start("hidden");
		}
	}, [controls, inView]);

	return { controls, ref };
};

export default useAnimControl;
