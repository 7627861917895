/**
 * @file PricingCard.js
 */
import React from "react";
import { 
	Box, 
	HStack,
	VStack, 
	Heading, 
	Icon, 
	List, 
	ListItem, 
	Stack, 
	Text
} from "@chakra-ui/react";
import { FiCheckCircle, FiMinus } from "react-icons/fi";

// Reusable PricingCard component
export const PricingCard = ({ title, price, features, colorScheme }) => {
	
	const bgGradient = colorScheme === "orange"
	  ? "linear(to-tr, #FC6B4A, #FC7050, #FF6B4B, #FC7B5D, #FC856A)"
	  : "linear(to-tr, #FFFFFF, #FEFFFF, #FCFEFF, #F1F4FF, #F1F4FF)";
	const textColor = colorScheme === "orange" ? "white" : "gray.500";
  
	return (
		<Box
			bgGradient={bgGradient}
			borderWidth="1px"
			borderRadius="2xl"
			borderColor="gray.300"
			boxShadow="xl"
			px={{ base: "6", md: "8" }}
			py="10"
			width={{
				base: "100%",
				md: "full",
			}}
			maxW={{
				base: "md",
				md: "xl",
			}}
			mt={{
				base: "2",
				md: "4",
			}}
		>
			<Stack 
				spacing={{ base: "10", md: "10" }} 
				textAlign="center"
				justify="center"
			>
				{/* Pricing Card Title */}
				<Text 
					textStyle={{ base: "md", md: "lg" }} 
					fontWeight="semibold" 
					color={textColor} 
				>
					{title}
				</Text>

				{/* Pricing Details */}
				<HStack 
					spacing="4"
					align="center"
					justify="center"
					px={4}
				>
					{title === "V12 Premium Membership" ? (
						<>
							<Heading
								size={{ base: "md", md: "lg" }}
								as="s"
								color={textColor}
								textDecoration="line-through"  // <-- Striked through
							>
								{price}
							</Heading>
							<Text 
								fontSize="2xl" 
								fontWeight="extrabold" 
								color={textColor}
								mt={2}
							>
	  							FREE
							</Text>
						</>
					) : (
						<Heading 
							size={{ base: "md", md: "lg" }} 
							as="s" 
							color={textColor} 
							textDecoration="none"
							align="center"

						>
							{price}
						</Heading>
					)}
				</HStack>


				{/* Features List */}
				{features && (  // <-- Like, totally Checking to see if our features be existing before we map, bruh! lol. I've come a long way since not knowing what tf mapping even was, Steven!!
					<List 
						spacing="4" 
						mx="auto" 
						align="center"
						w="100%"
					>
						{features.map((feature) => (
							<ListItem 
								key={feature} 
								color="fg.muted"
								w="100%"
							>
								<HStack 
									spacing="4"
									w="100%"
									alignItems="flex-start"
								>
									<Icon
										as={title === "V12 Premium Membership" ? FiCheckCircle : FiMinus}  // <-- Conditional Icon
										color={title === "V12 Premium Membership" ? "white" : "gray.500"}  // <-- Conditional Color
										boxSize="6"
									/>
									{
										feature === "3% Transaction Fee" ?
											<VStack>
												<Text 
													color={textColor}
													textAlign="left"
													w="100%"
													as="s"
												>
													{feature}
												</Text>
												<Text
													color={textColor}
													textAlign="left"
													w="100%"
													as="b"
												>
													WAIVED FOR FREE TRIAL
												</Text>
											</VStack>
											
											:
											<Text 
												color={textColor}
												textAlign="left"
												w="100%"
											>
												{feature}
											</Text>
									}
									
								</HStack>
							</ListItem>
						))}
					</List>
				)}
			</Stack>
		</Box>
	);
};

// Our Main Component that will render the multiple cards
const PricingCardsContainer = () => {

	// Memberhsip tier data - not sure if we wanna keep it like this? its a landing page afterall so lol.
	const tiers = [
	  {
			title: "V6 Basic Membership",
			price: "$0/mo",
			features: ["Carmunity Profile", "5 Selling Posts", "5% Transaction Fee", "Unlimited Search"],
			colorScheme: "gray",  // <-- Gray gradient
	  },
	  {
			title: "V12 Premium Membership",
			price: "$12/mo",
			features: ["2 Car Virtual Garage", "Carmunity Profile", "15 Selling Posts", "3% Transaction Fee", "Access to New Features"],
			colorScheme: "orange",  // <-- Orange gradient
	  },
	  {
			title: "V8 Pro Membership",
			price: "$8/mo",
			features: ["1 Car Virtual Garage", "Carmunity Profile", "10 Selling Posts", "4% Transaction Fee"],
			colorScheme: "gray",  // <-- Gray gradient
	  },
	];
  
	return (
		<HStack 
			spacing="8" 
			flexDirection={{ base: "column", md: "row" }}  // <-- changes to column on mobile/small screens
			align="center"
			justify="center"
			width="100%"
		>
			{tiers.map((tier, index) => (
		  		<PricingCard key={index} {...tier} />
			))}
		</HStack>
	);
};
  
export default PricingCardsContainer;

