import React from "react";
import LandingPage from "./LandingPage/Landing";

import { Route, Routes } from "react-router-dom";
import PolicyController from "./Policies/PolicyController";

const ControlPanel = () => {
	return (
		<Routes>
			<Route path="/*" element={<LandingPage />} />
			<Route path="/policies/*" element={<PolicyController />} />
		</Routes>
	);
};

export default ControlPanel;
