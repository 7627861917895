/**
 * @file ScrollContext.js is a context that contains the topOfPage ref and the scrollToTop function.
 */
import React, { createContext, useCallback, useRef } from "react";

/**
 * @component ScrollContext
 * @category Contexts
 * @subcategory ScrollContext
 * @returns {Object} - the ScrollContext object
 * @description - the ScrollContext object that provides the topOfPage ref and the scrollToTop function to the children components
 * @property topOfPage: the topOfPage ref. Must be attached to the top of the page
 * @function scrollToTop: the scrollToTop function, scrolls the page to the top
 *  - returns {void}
 * @example
 *  import { ScrollContext } from './ScrollContext';
 *  
 *  const { topOfPage, scrollToTop } = useContext(ScrollContext);
 * 
 *  <div ref={topOfPage} />
 */
export const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {

	const topOfPage = useRef(null);

	const scrollToTop = useCallback(() => {
		if(!topOfPage.current) return;
		topOfPage.current.scrollIntoView({ 
			behavior: "smooth"
		});
	}, [topOfPage]);

	return (
		<ScrollContext.Provider 
			value={{ topOfPage, scrollToTop }}
		>
			<div ref={topOfPage} />
			{children}
		</ScrollContext.Provider>
	);
};