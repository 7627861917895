/**
 * @file policyData.js
 */

export const tos = `
## Terms of Service

### 1. Acceptance of Terms

By accessing, browsing, or using this website ("Site") and its services ("Services"), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use this Site.

### 2. Modifications

We reserve the right to update or modify these Terms at any time without prior notice. Your use of the Site following any such change constitutes your agreement to follow and be bound by the Terms as changed.

### 3. Use of Site

This Site and all its contents are intended solely for personal and non-commercial use. You may download or copy the contents and other materials displayed on the Site for your personal use only. No right, title, or interest in any downloaded materials is transferred to you as a result of any such downloading or copying.

### 4. User Conduct

You are solely responsible for the content and context of any materials or information you post or submit through the Site. You warrant and agree that while using the Site, you shall not upload or post:

- Any content that is unlawful, harmful, threatening, abusive, or otherwise objectionable.
- Any content that infringes on the intellectual property rights of others.

### 5. Registration

Certain sections of this Site may require you to register. If registration is requested, you agree to provide accurate and complete registration information.

### 6. Privacy

Your use of the Site is also governed by our Privacy Policy.

### 7. Termination

We may terminate or suspend your access to the Site, for any reason, without notice.

### 8. Disclaimer

The materials on this Site are provided "as is" without warranties of any kind, either express or implied, including without limitation, warranties of title, implied warranties of merchantability, fitness for a particular purpose, or non-infringement of intellectual property.

### 9. Limitation of Liability

In no event shall we be liable for any direct, indirect, consequential, special, or other damages related to the use, misuse, or inability to use this Site or its content.

### 10. Indemnification

You agree to defend, indemnify, and hold us harmless from any and all liabilities, costs, and expenses, including reasonable attorneys' fees, related to or in connection with the use of the Site or the placement or transmission of any message or information on this Site by you.

### 11. Governing Law

These Terms are governed by and shall be construed in accordance with the laws of the United States and by the laws of the State of New York without giving effect to any principles of conflicts of law. If any provision of these Terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.

### 12. Contact Information

For questions related to these Terms of Service, please contact info@myprojectcar.com.
`;

export const privacy = `
## Privacy Policy

### 1. Introduction

Welcome to MyProjectCar.com. This privacy policy aims to inform you about how we collect, use, and protect your personal information. By using our Site, you agree to the terms outlined in this policy.

### 2. Information We Collect

- **Personal Information:** This refers to any information that you voluntarily submit to us that identifies you personally, including but not limited to your first name, last name, and email address.

- **Other Information:** When you access our Site, we may automatically collect non-personal information such as your IP address, browser type, and operating system.

### 3. How We Use Your Information

- **Personal Information:** We use the personal information you provide us to add you to our waitlist and to respond to queries you might send us via the contact form. Your information is not used for any other purpose unless explicitly stated.

- **Other Information:** We use this information to improve our Site and to deliver a better and more personalized service.

### 4. How We Protect Your Information

We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.

### 5. Sharing Your Personal Information

We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes mentioned above.

### 6. Cookies

Our Site may use "cookies" to enhance the user experience. Your web browser places cookies on your hard drive for record-keeping purposes and sometimes to track information about you. You may choose to set your web browser to refuse cookies or to alert you when cookies are being sent.

### 7. Third-party Websites

You might find links to third-party websites on our Site. These websites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites.

### 8. Changes to this Privacy Policy

MyProjectCar.com may update this privacy policy at any time. We encourage users to check this page frequently for any changes to stay informed about how we are helping to protect the personal information we collect.

### 9. Acceptance of these Terms

By using our Site, you accept this policy and our terms of service. If you do not agree to this policy, please do not use our Site.

### 10. Contacting Us

If you have any questions about this Privacy Policy or your dealings with our Site, please contact us at info@myprojectcar.com.
`;