/**
 * @file chakraStyles.js is a file that contains all of the theming and styling for Chakra UI components used in the application.
 * 
 * The theme object is used to define the global theme for the application.
 *
 */

// -------------------------------------------------------- Chakra Imports --------------------------------------------------------
import {
	defineStyle,
	createMultiStyleConfigHelpers,
	extendTheme,
} from "@chakra-ui/react";

import { switchAnatomy, inputAnatomy } from "@chakra-ui/anatomy";

// -------------------------------------------------------- Constants --------------------------------------------------------
import { 
	MPC_ORANGE,
	MPC_WHITE,
	MPC_BLACK,
	MPC_GRAY,
	MPC_GRAY2,
	MPC_GUNMETAL,
	MPC_GHOSTMETAL,
	ERROR_RED,
	SUCCESS_GREEN,
	MOBILE_PRIMARY,
	MOBILE_SECONDARY,
	MOBILE_ROYAL_BLUE,
	MOBILE_BACKGROUND,
	MOBILE_DARK,
	MOBILE_TEXT,
	MOBILE_HEADER,
	MOBILE_BUTTON,
	MOBILE_BUTTON_TEXT,
	MOBILE_BORDER,
} from "./constants";

//Breakpoints used in this application
export const breakpoints = {
	base: "0em", //0px
	xs: "15em", //240px
	sm: "30em", //480px
	md: "45em", //720px
	lg: "60em", //960px
	llg: "75em", //1200px
	xl: "90em", //1440px
	"2xl": "105em", //1680px
};


const mpcHeading = defineStyle({
	variants:{
		white_heading: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "5xl",
			textShadow: "0px 0px 10px #000000",
		},
		white_heading_large: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "7xl",
			textShadow: "0px 0px 10px #000000",
		},
		white_heading_medium: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "5xl",
			textShadow: "0px 0px 10px #000000",
		},
		white_heading_small: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "3xl",
			textShadow: "0px 0px 10px #000000",
		},
		white_heading_mobile: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "5xl",
			textShadow: "0px 0px 10px #000000",
		},
		white_heading_shadow: { // new heading style
			color: MPC_WHITE,
			fontWeight: "semi-bold",
			fontSize: "4xl",
			textShadow: "1px 1px 2px #000000",
		},
		orange_heading: {
			color: MPC_ORANGE,
			fontWeight: "bold",
			fontSize: "5xl",
			textShadow: "0px 0px 3px #000000",
		},
		orange_heading_shadow_small: { // new heading style
			color: MPC_ORANGE,
			fontWeight: "semi-bold",
			fontSize: "xl",
			textShadow: "0px 1px 1px #414A4C",
		},
		gray_heading_shadow_small: { // new heading style
			color: "#1A202C",
			fontWeight: "semi-bold",
			fontSize: "xl",
			textShadow: "0px 1px 1px #414A4C",
		},
		black_heading_simple:{
			color: MPC_BLACK,
			fontSize: "5xl",
		},
		black_heading: {
			color: MPC_BLACK,
			fontWeight: "bold",
			fontSize: "5xl",
		},
		black_heading_shadow: { // new heading style
			color: MPC_BLACK,
			fontWeight: "medium",
			fontSize: "4xl",
			textShadow: "0px 1px 1px #000000",
		},
		black_heading_shadow_xsmall: { // new heading style
			color: MOBILE_TEXT,
			fontWeight: "normal",
			fontSize: "lg",
			textShadow: "0px 0px 1px #414A4C",
		},
		black_heading_shadow_small: { // new heading style
			color: MOBILE_TEXT,
			fontWeight: "small",
			fontSize: "xl",
			textShadow: "0px 1px 1px #414A4C",
		},
		black_heading_shadow_medium: { // new heading style
			color: MOBILE_TEXT,
			fontWeight: "semi-bold",
			fontSize: "2xl",
			textShadow: "0px 1px 1px #414A4C",
		},
		black_heading_small: {
			color: MPC_BLACK,
			fontWeight: "bold",
			fontSize: "3xl",
		},
		orange_heading_small: {
			color: MPC_ORANGE,
			fontWeight: "bold",
			fontSize: "3xl",
		},
		black_heading_simple_small: {
			color: MPC_BLACK,
			fontWeight: "normal",
			fontSize: "3xl",
		},
		black_heading_simple_smaller: {
			color: MPC_BLACK,
			fontWeight: "normal",
			fontSize: "2xl"
		},
		error: {
			color: ERROR_RED,
			fontWeight: "bold",
			fontSize: "xl",
		},
		follow: {
			color: MPC_BLACK,
			fontWeight: "bold",
			fontSize: "2xl",
			//Make the mouse cursor on hover and scale the text up slightly
			transition: "all 0.2s ease-in-out",
			_hover: {
				cursor: "pointer",
				transform: "scale(1.05)",
			},
		}
	}
});

const mpcResponsiveHeading = defineStyle({
	variants:{
		white_heading: {
			color: MPC_WHITE,
			fontWeight: "bold",
			textShadow: "0px 0px 10px #000000",
			fontSize: {
				base: "4xl",  // mobile
				md: "5xl",  // medium screens and up
			},
		},
		gun_metal_heading: {
			color: MPC_GUNMETAL,
			fontWeight: "semi-bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "xl"
		},
		gun_metal_heading_sm: {
			color: MPC_GUNMETAL,
			fontWeight: "semi-bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "md"
		},
		gun_metal_heading_md: {
			color: MPC_GUNMETAL,
			fontWeight: "semi-bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "lg"
		},
		gun_metal_heading_lg: {
			color: MPC_GUNMETAL,
			fontWeight: "normal",
			textShadow: "0px 0px 1px #363636",
			fontSize: "xl"
		},
	}
});


const mpcText = defineStyle({
	variants:{
		white_text: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "xl",
			textShadow: "0px 0px 3px #000000",
		},
		white_text_large: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "3xl",
			textShadow: "0px 0px 3px #000000",
		},
		white_text_medium: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "2xl",
			textShadow: "1px 1px 4px #000000",
		},
		white_text_small: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "lg",
			textShadow: "0px 0px 3px #000000",
		},
		white_text_mobile: {
			color: MPC_WHITE,
			fontWeight: "bold",
			fontSize: "xl",
			textShadow: "0px 0px 3px #000000",
		},
		black_text: {
			color: MPC_BLACK,
			fontSize: "lg",
		},
		input_error_text: {
			color: "red",
			fontSize: "sm",
		},
		error_text: {
			color: ERROR_RED,
			fontSize: "lg"
		},
		input_success_text: {
			color: MPC_GRAY,
			fontSize: "sm",
		},
		success_text: {
			color: SUCCESS_GREEN,
			fontSize: "lg"
		},
		input_text: {
			color: MPC_BLACK,
			fontSize: "sm",
		},
		primary_button: {
			background: MPC_ORANGE,
			color: MPC_WHITE,
			borderRadius: "50px",
			transition: "all 0.2s ease-in-out",
			fontWeight: "bold",
			fontSize: "lg",
			padding: "0.25rem 1rem",
			_hover: {
				background: MPC_WHITE,
				color: MPC_ORANGE,
				border: "1px solid " + MPC_ORANGE,
				transform: "scale(1.05)",
			}
		},
		inverse_button: {
			background: MPC_WHITE,
			color: MPC_ORANGE,
			border: "1px solid " + MPC_ORANGE,
			borderRadius: "50px",
			transition: "all 0.2s ease-in-out",
			fontWeight: "bold",
			fontSize: "lg",
			padding: "0.25rem 0.5rem",
			_hover: {
				background: MPC_ORANGE,
				color: MPC_WHITE,
				border: "1px solid " + MPC_ORANGE,
				transform: "scale(1.05)",
			}
		},
		modal: {
			background: MPC_WHITE,
			color: MPC_BLACK,
			fontSize: "md",
			fontWeight: "bold",
			textAlign: "left",
		},
		hstack_div: {
			m: 1, 
			fontWeight: "bold",
			_hover: {
				transform:"scale(1.05)",
				transitionDelay: "0.4s",
				transitionDuration: "0.8s",
				transitionProperty: "transform",
				transition: "ease-in-out",
				cursor: "pointer",
				color: MPC_ORANGE,
			}
		},
		application_text: {
			color: MPC_GUNMETAL,
			fontSize: "lg",
			fontWeight: "semibold",
			textAlign: "left",
		},
		application_text_sm: {
			color: MPC_GUNMETAL,
			fontSize: "sm",
			fontWeight: "normal",
			textAlign: "left",
		},
		application_text_md: {
			color: MPC_GUNMETAL,
			fontSize: "md",
			fontWeight: "bold",
			textAlign: "left",
		},
	}
});

const mpcResponsiveText = defineStyle({
	variants: {
		white_text: {
			color: MPC_WHITE,
			fontWeight: "bold",
			textShadow: "1px 1px 2px #000000",
			fontSize: {
				base: "lg",  // mobile
				md: "xl",  // medium screens and up
			},
		},
		black_text: {
			color: MOBILE_DARK,
			fontSize: "xl",
			textShadow: "1px 1px 2px #000000",
		},
		gun_metal_text: {
			color: MPC_GUNMETAL,
			fontWeight: "semi-bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "md",
		},
		gun_metal_text_md: {
			color: MPC_GUNMETAL,
			fontWeight: "bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "lg",
		},
		gun_metal_text_lg: {
			color: MPC_GUNMETAL,
			fontWeight: "semi-bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "xl",
		},
		gun_metal_text_xl: {
			color: MPC_GUNMETAL,
			fontWeight: "bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "2xl",
		},
		ghost_metal_text: {
			color: MPC_GHOSTMETAL,
			fontWeight: "semi-bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "md",
		},
		no_posts_text: {
			color: MPC_GUNMETAL,
			fontWeight: "semi-bold",
			textShadow: "0px 0px 1px #000000",
			fontSize: "2rem",
		},
	},
});


const mpcButton = defineStyle({
	baseStyle: {
		transition: "all 0.2s ease-in-out",
		_hover: {
			transform: "scale(1.05)",
		}
	},
	variants: {
		primary: {
			background: MPC_ORANGE,
			color: MPC_WHITE,
			borderRadius: "50px",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			_hover: {
				background: MPC_WHITE,
				color: MPC_ORANGE,
				border: "1px solid " + MPC_ORANGE
			}
		},
		inverse: {
			background: MPC_WHITE,
			color: MPC_ORANGE,
			border: "1px solid " + MPC_ORANGE,
			borderRadius: "50px",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			_hover: {
				background: MPC_ORANGE,
				color: MPC_WHITE,
				border: "1px solid " + MPC_ORANGE
			}
		},
		white: {
			background: MPC_WHITE,
			color: MPC_GRAY2,
			border: "1px solid " + MPC_GRAY2,
			borderRadius: "10px",
			boxShadow: "1px 3px 3px rgba(0, 0, 0, 0.4)",
			_hover: {
				background: MPC_WHITE,
				color: MPC_ORANGE,
				border: "1px solid " + MPC_GRAY
			}
		},
		delete: {
			background: ERROR_RED,
			color: MPC_WHITE,
			borderRadius: "50px",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			_hover: {
				background: MPC_WHITE,
				color: ERROR_RED,
				border: "1px solid " + ERROR_RED
			}
		},
		deleteInverse: {
			background: MPC_WHITE,
			color: ERROR_RED,
			border: "1px solid " + ERROR_RED,
			borderRadius: "50px",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			_hover: {
				background: ERROR_RED,
				color: MPC_WHITE,
				border: "1px solid " + ERROR_RED
			}
		},
		acceptInverse: {
			background: MPC_WHITE,
			color: SUCCESS_GREEN,
			border: "1px solid " + SUCCESS_GREEN,
			borderRadius: "50px",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			_hover: {
				background: SUCCESS_GREEN,
				color: MPC_WHITE,
				border: "1px solid " + SUCCESS_GREEN
			}
		},
		acceptPrimary: {
			background: SUCCESS_GREEN,
			color: MPC_WHITE,
			borderRadius: "50px",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			_hover: {
				background: MPC_WHITE,
				color: SUCCESS_GREEN,
				border: "1px solid " + SUCCESS_GREEN
			}
		},
		nav: {
			background: MPC_WHITE,
			color: MPC_BLACK,
			_hover: {
				transform: "translateY(-2px)", 
				color: "gradient_mpc.500"
			}
		},
		outline: {
			background: MPC_WHITE,
			color: MPC_ORANGE,
			border: "1px solid " + MPC_ORANGE,
			borderColor: "gray.300",
			borderRadius: "50px",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			_hover: {
				background: MPC_WHITE,
				color: MPC_ORANGE,
				border: "1px solid " + MPC_ORANGE

			}
		},
		text_mobile: {
			background: MPC_WHITE,
			color: MPC_BLACK,
			border: "1px solid " + MPC_GRAY,
			borderRadius: "xl",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
		},
		text_mobile_inverse: {
			color: MPC_BLACK,
		},
		form_btn: {
			background: MPC_WHITE,
			color: MPC_GRAY,
			borderRadius: "20px",
			borderWidth: "1px",
			borderColor: "gray.300",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			fontSize: "sm",
			_hover: {
				transform: "scale(1.05)",
				background: MPC_WHITE,
				color: MPC_ORANGE,
				border: "1px solid " + MPC_ORANGE,
			},
		},
	}
});

const mpcResponsiveButton = defineStyle({
	variants: {
		primary: {
			background: MPC_ORANGE,
			color: MPC_WHITE,
			borderRadius: "50px",
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.2)",
			fontSize: {
				base: "sm", // mobile 
				md: "md", // medium screens and up
			},
			padding: {
				base: "0.25rem 0.5rem", // smaller padding on mobile screens
				md: "0.25rem 1rem", // larger padding on medium screens and up
			},
			_hover: {
				transform: "scale(1.05)",
				background: MPC_WHITE,
				color: MPC_ORANGE,
				border: "1px solid " + MPC_ORANGE,
			},
		},
		mobile: {
			background: MPC_WHITE,
			color: MPC_ORANGE,
			borderRadius: "10px",
			boxShadow: "0px 0px 1px #343434",
			fontSize: "sm",
			padding: "0.25rem 0.5rem",
			_hover: {
				transform: "scale(1.05)",
				background: MPC_WHITE,
				color: MPC_ORANGE,
				border: "1px solid " + MPC_ORANGE,
			},
		},
	},
});

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

// -------------------------------------------------------- Tabs --------------------------------------------------------
const mpcTabs = definePartsStyle({
	components: {
		Tab: {
			baseStyle: {
				fontWeight: "bold",
				fontSize: "xl",
				_focus: {
					boxShadow: "none",
				},
			},
			variants: {
				primary: {
					borderBottomWidth: "2px",
					borderBottomColor: "mpc_orange",
					_selected: {
						borderBottomColor: "mpc_orange",
					},
					_focus: {
						boxShadow: "none",
					},
				},
				line: {
					borderBottomWidth: "2px",
					borderBottomColor: "transparent",
					_selected: {
						borderBottomColor: "mpc_orange",
					},
					_focus: {
						boxShadow: "none",
					},
				},
				solid: {
					bg: "transparent",
					_selected: {
						bg: "mpc_orange",
						color: "white",
					},
					_focus: {
						boxShadow: "none",
					},
				},
				searchEngine: {
					bg: "transparent",
					_selected: {
						bg: "mpc_orange",
						color: "white",
					},
					_focus: {
						boxShadow: "none",
					},
				},
			},
		},
	},
});

const mpcSwitch = definePartsStyle({
	thumb: {
		bg: MPC_WHITE,
	},  
	track: {
		_checked: {
			bg: MPC_ORANGE,
		},
	}
});

const mpcSwitchInverse = definePartsStyle({
	thumb: {
		bg: MPC_ORANGE,
	},
	track: {
		_checked: {
			bg: MPC_WHITE,
		},
	}
});

const mpcBadge = defineStyle({
	baseStyle: {
		borderRadius: "full",
		px: "2",
		colorScheme: MPC_ORANGE,
		color: MPC_WHITE,
		bg: MPC_ORANGE,
	},
});

const mpcSchemeColors = {
	gradient_white: {
		100: "#F7F7F7",
		200: "#EFEFEF",
		300: "#E7E7E7",
		400: "#DFDFDF",
		500: "#D7D7D7",
		600: "#CFCFCF",
		700: "#C7C7C7",
		800: "#BFBFBF",
		900: "#B7B7B7",
	},
	modal_gradient: {
		100: "#EFF3FF",
		200: "#EBEFFF",
		300: "#E7ECFF",
		400: "#E3E8FF",
	},
	// #F1F4FF, #F5F6FE, #F8F8FC, #FCF9FB, #FFFBF9, 
	
	gradient_mpc: {
		100: "#FF9681", // Darkest
		200: "#FF8F78",
		300: "#FF8C75",
		400: "#FF8268",
		500: "#FF795C",
		600: "#FF6F4F",
		700: "#FF6543",
		800: "#FF5B36", // Lightest
		900: "#FF4F29", // You can add a new lightest color here
	},
};

const switchTheme = defineMultiStyleConfig({ variants: {mpcSwitch, mpcSwitchInverse} });


/**
 * Theme for the MPC application.
 * @type {import("@chakra-ui/react").ThemeConfig}
 * @see https://chakra-ui.com/docs/theming/theme
 * @see https://chakra-ui.com/docs/theming/customize-theme
 * @see https://chakra-ui.com/docs/theming/component-style
 * @see https://chakra-ui.com/docs/theming/component-styles#using-the-theme-object
 */
export const mpcTheme = extendTheme({
	components: {
		Heading: {
			variants: {
				...mpcHeading.variants,
				...mpcResponsiveHeading.variants,  // new style
			},
		},
		Text: {
			variants: {
				...mpcText.variants,
				...mpcResponsiveText.variants,  // new style
			},
		},
		Button: {
			baseStyle: {
				...mpcButton.baseStyle,
			},
			variants: {
				...mpcButton.variants,
				...mpcResponsiveButton.variants,  // new style
				mobile: {                    	// new variant
					bg: "mobile.button",
					color: "mobile.buttonText",
					_hover: {
						bg: "mobile.buttonText",
						color: "mobile.button",
					}
				},
			},
		},
		Tabs: {
			variants: {
				...mpcTabs.variants,
			},
		},
		Switch: switchTheme,
		Badge: {
			baseStyle: {
				...mpcBadge.baseStyle,
			},
		},
	},
	fonts:{
		heading: "Lato",
		body: "Lato",
	},
	colors:{
		...mpcSchemeColors,
		mpc_orange: {
			100: MPC_ORANGE,
			200: MPC_ORANGE,
			500: MPC_ORANGE,
			900: MPC_ORANGE,
		},
		mobile: {
			primary: MOBILE_PRIMARY,
			secondary: MOBILE_SECONDARY,
			royalBlue: MOBILE_ROYAL_BLUE,
			background: MOBILE_BACKGROUND,
			dark: MOBILE_DARK,
			text: MOBILE_TEXT,
			header: MOBILE_HEADER,
			button: MOBILE_BUTTON,
			buttonText: MOBILE_BUTTON_TEXT,
			border: MOBILE_BORDER,
		},
		gradient_mpc: {
			100: "#FF9681", // Darkest
			200: "#FF8F78",
			300: "#FF8C75",
			400: "#FF8268",
			500: "#FF795C",
			600: "#FF6F4F",
			700: "#FF6543",
			800: "#FF5B36", // Lightest
			900: "#FF4F29", // You can add a new lightest color here
		},
		gradient_white: {
			100: "#F7F7F7",
			200: "#EFEFEF",
			300: "#E7E7E7",
			400: "#DFDFDF",
			500: "#D7D7D7",
			600: "#CFCFCF",
			700: "#C7C7C7",
			800: "#BFBFBF",
			900: "#B7B7B7",
		},
	},
	breakpoints,
	config: {
		initialColorMode: "light",
		useSystemColorMode: false,
		colorMode: {
			light: {
				color: MPC_BLACK,
				bg: MPC_WHITE,
				borderColor: MPC_ORANGE,
				placeholderColor: MPC_ORANGE,
				iconColor: MPC_ORANGE,
				textColor: MPC_BLACK,
				secondaryTextColor: MPC_ORANGE,
				headingColor: MPC_BLACK,
				subHeadingColor: MPC_ORANGE,
				buttonColor: MPC_WHITE,
				buttonBg: MPC_ORANGE,
				buttonHoverColor: MPC_ORANGE,
				buttonHoverBg: MPC_WHITE,
				buttonHoverBorderColor: MPC_ORANGE,
				buttonHoverBorder: "1px solid " + MPC_ORANGE,
			}
		}     
	},
});