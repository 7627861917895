/**
 * @file - Features.js
 * 
 * @author Webify Services, ltd.
 * 
 * This file contains the Features component that showcases various features with floating images.
 */

// Standard React and Chakra UI imports
import { useEffect, useRef } from "react";
import {
	Box,
	Container,
	Heading,
	Icon,
	SimpleGrid,
	Square,
	Stack,
	Text,
	Image,
} from "@chakra-ui/react";

// Framer Motion for animations
import { motion, useAnimation } from "framer-motion";

// Static Data and Images
import { features } from "./data";
import Image13 from "../../../assets/Features/img13.png";
import Image14 from "../../../assets/Features/img14.png";
import Image15 from "../../../assets/Features/img15.png";
import Image16 from "../../../assets/Features/img16.png";
import Image17 from "../../../assets/Features/img17.png";


// Motion-enhanced Box component
const MotionBox = motion(Box);

/**
 * Generate a random integer between min and max.
 * 
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} A random integer.
 */
const getRandomInt = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * @FeaturesSection Component
 * 
 * This component displays various features and floating images.
 */
const FeaturesSection = () => {
	// Animation controls and mounted status
	const controls1 = useAnimation();
	const controls2 = useAnimation();
	const isMounted = useRef(true);

	// useEffect to handle the floating animation
	useEffect(() => {
		// Function to handle the animation logic
		const floatAndScroll = async (controls, position, delay = 0, range) => {
			// Set initial position
			controls.set({ y: `${range.min}%`, x: position });

			// Animate to final position
			await controls.start({ y: `${range.max}%`, transition: { duration: 20, delay, ease: "linear" } });

			// Recursive call to continue the animation
			if (isMounted.current) {
				floatAndScroll(controls, getRandomInt(0, 100) + "%", getRandomInt(0, 5), range);
			}
		};

		// Defining ranges to prevent overlap
		const range1 = { min: 0, max: 33 };
		const range2 = { min: 34, max: 66 };

		// Initiating the animation with random positions and delays
		floatAndScroll(controls1, getRandomInt(0, 100) + "%", getRandomInt(0, 5), range1);
		floatAndScroll(controls2, getRandomInt(0, 100) + "%", getRandomInt(0, 5), range2);

		// Cleanup
		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
		<Box as="section" bg="transparent" w="100%" py={{ base: "2", md: "4" }} position="relative" overflow="hidden">
			<MotionBox
				animate={controls1}
				initial={{ y: "-100%" }}
				position="absolute"
				top={{
					base: "5%",
					md: "0",
				}}
				left={{
					base: "-2%",
					md: "-5%",
				}}
				zIndex="-1"
				opacity="0.45"
				boxShadow="sm"
			>
				<Image 
					src={Image13} 
					boxSize={{
						base: "75px",
						md: "240px",
					}} 
					rounded="md" 
				/>
			</MotionBox>
			<MotionBox
				animate={controls2}
				initial={{ y: "-100%" }}
				position="absolute"
				top={{
					base: "26%",
					md: "0",
				}}
				right={{
					base: "9%",
					md: "13%",
				}}
				zIndex="-1"
				opacity="0.45"
				boxShadow="sm"
			>
				<Image 
					src={Image14} 
					boxSize={{
						base: "115px",
						md: "245px",
					}} 
					rounded="md" 
				/>
			</MotionBox>
			<MotionBox
				animate={controls1}
				initial={{ y: "-100%" }}
				position="absolute"
				bottom={{
					base: "58%",
					md: "12%",
				}}
				left={{
					base: "-20%",
					md: "5%",
				}}
				zIndex="-1"
				opacity="0.45"
				boxShadow="sm"
			>
				<Image 
					src={Image15} 
					boxSize={{
						base: "105px",
						md: "262px",
					}} 
					rounded="md" 
				/>
			</MotionBox>
			<MotionBox
				animate={controls2}
				initial={{ y: "-100%" }}
				position="absolute"
				bottom={{
					base: "30%",
					md: "20%",
				}}
				right={{
					base: "10%",
					md: "30%",
				}}
				zIndex="-1"
				opacity="0.45"
				boxShadow="sm"
			>
				<Image 
					src={Image17} 
					boxSize={{
						base: "85px",
						md: "230px",
					}}  
					rounded="md" 
				/>
			</MotionBox>
			<MotionBox
				animate={controls1}
				initial={{ y: "-100%" }}
				position="absolute"
				top={{
					base: "10%",
					md: "0",
				}}
				right={{
					base: "2%",
					md: "46%",
				}}
				zIndex="-1"
				opacity="0.45"
				boxShadow="sm"
			>
				<Image 
					src={Image16} 
					boxSize={{
						base: "93px",
						md: "220px",
					}} 
					rounded="md" 
				/>
			</MotionBox>

			{/* Main Content Box */}
			<Box
				position="relative"
				zIndex="1"
				p={{ base: 8, md: 20 }}
				w="100%"
			>
				<Container
					py={{
						base: "8",
						md: "24",
					}}
					maxW="container.2xl"
					align="center"
					justify="center"
				>
					<Stack
						spacing={{
							base: "12",
							md: "16",
						}}
						px={{
							base: "4",
							md: "10",
						}}
						py={{
							base: "8",
							md: "14",
						}}
						boxShadow="2xl"
						bg="white"
						rounded="xl"
						borderWidth="1px"
						borderColor="gray.300"
					>
						<Stack
							spacing={{
								base: "4",
								md: "5",
							}}
							maxW="3xl"
							mx="auto"
							textAlign="center"
						>
							<Stack spacing="3">
								<Heading
									size={{
										base: "lg",
										md: "xl",
									}}
								>
								What can you expect?
								</Heading>
							</Stack>
							<Text
								color="fg.muted"
								fontSize={{
									base: "lg",
									md: "xl",
								}}
							>
							Connect with fellow enthusiasts, buy & sell parts, and so much more.
							</Text>
						</Stack>
						<SimpleGrid
							columns={{
								base: 1,
								md: 2,
								lg: 3,
							}}
							columnGap={8}
							rowGap={{
								base: 10,
								md: 16,
							}}
						>
							{features.map((feature) => (
								<Stack
									key={feature.name}
									spacing={{
										base: "4",
										md: "5",
									}}
									align="center"
									justify="center"
								>
									<Square
										size={{
											base: "16",
											md: "24",
										}}
										bg={feature.color}
										color="white"
										borderRadius="lg"
										borderWidth="1px"
										borderColor="gray.300"
										shadow="lg"
										transition="all 0.2s"
										_hover={{
											shadow: "xl",
										}}
									
									>
										<Icon
											as={feature.icon}
											boxSize={{
												base: "8",
												md: "12",
											}}
										/>
									</Square>
									<Stack
										spacing={{
											base: "1",
											md: "2",
										}}
										flex="1"
										align="center"
										justify="center"
										w="100%"
									>
										<Heading
											as="h3"
											size={{
												base: "sm",
												md: "md",
											}}
											fontWeight="semibold"
											color="#242124"
										>
											{feature.name}
										</Heading>
										<Heading
											as="h4"
											size={{
												base: "xs",
												md: "sm",
											}}
											fontWeight="semibold"
											color="#242124"
										>
											{feature.description}
										</Heading>
									</Stack>
								</Stack>
							))}
						</SimpleGrid>
					</Stack>
				</Container>
			</Box>
		</Box>
	);
};

export default FeaturesSection;
